import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/search",
      name: "search",
      component: () => import("./views/search/index.vue"),
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("./views/contacts/index.vue"),
    },
    {
      path: "/press",
      name: "press",
      component: () => import("./views/press/index.vue"),
    },
    {
      path: "/press/news",
      name: "news",
      component: () => import("./views/news/index.vue"),
    },
    {
      path: "/press/news/:id",
      name: "newsItem",
      component: () => import("./views/news/open.vue"),
    },
    {
      path: "/activity/:id",
      name: "activity",
      component: () => import("./views/activity/index.vue"),
    },
    {
      path: "/press/media",
      name: "media",
      component: () => import("./views/media/index.vue"),
    },
    {
      path: "/departments",
      name: "sub_departments",
      component: () => import("./views/sub_departments/index.vue"),
    },
    {
      path: "/departments/:id",
      name: "sub_department",
      component: () => import("./views/sub_departments/open.vue"),
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("./views/projects/index.vue"),
    },
    {
      path: "/projects/:id",
      name: "project",
      component: () => import("./views/projects/open.vue"),
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("./views/documents/index.vue"),
    },
    {
      path: "/documents/:id",
      name: "document",
      component: () => import("./views/documents/open.vue"),
    },
    {
      path: "/persons",
      name: "persons",
      component: () => import("./views/persons/list/index.vue"),
    },
    {
      path: "/persons/:id",
      name: "person",
      component: () => import("./views/persons/open/index.vue"),
    },
    {
      path: "/appeals",
      name: "appeals",
      component: () => import("./views/appeals/index.vue"),
      children: [
        {
          path: "about",
          name: "appeals-about",
          component: () => import("./views/appeals/about/index.vue"),
        },
        {
          path: "faq",
          name: "appeals-faq",
          component: () => import("./views/appeals/faq/index.vue"),
        },
        {
          path: "form",
          name: "appeals-form",
          component: () => import("./views/appeals/form/index.vue"),
        },
        {
          path: "posts/:id?",
          name: "appeals-posts",
          component: () => import("./views/appeals/posts/index.vue"),
        },
      ],
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
