<template>
  <div id="app">
    <HeaderComponent />
    <MobileMenu v-if="$store.state.showMobileMenu" />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <ClientOnly>
      <Notifications position="bottom right" />
    </ClientOnly>
    <ModalsComponent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "components/header/index.vue";
import FooterComponent from "components/footer/index.vue";
import MobileMenu from "components/menus/MobileMenu.vue";
import DEFAULT from "gql/queries/default.graphql";
import ClientOnly from "vue-client-only";
import ModalsComponent from "components/modals/index.vue";

export default {
  name: "App",
  async mounted() {
    await this.$apolloProvider.defaultClient
      .query({
        query: DEFAULT,
      })
      .then(({ data }) => {
        this.$store.dispatch("save", data);
      })
      .catch(() => {});
  },
  metaInfo: {},
  components: {
    ModalsComponent,
    MobileMenu,
    FooterComponent,
    HeaderComponent,
    ClientOnly,
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
