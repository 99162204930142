<template>
  <nav class="m-menu">
    <div @click="closeMobileMenu" class="m-menu__background" />
    <div class="m-menu__container">
      <button @click="closeMobileMenu" class="m-menu__close">
        <IconComponent category="default" name="close" />
      </button>
      <ul class="m-menu__links">
        <li v-for="(link, i) in links" :key="i">
          <div>
            <router-link :to="link.route">
              <span>{{ link.title }}</span>
            </router-link>
            <button @click="toggleLink(i)">
              <IconComponent v-if="link.subLinks && link.subLinks.length" name="arrow-down" />
            </button>
          </div>
          <ul v-if="link.subLinks && link.subLinks.length && activeLink === i">
            <li v-for="(subLink, i) in link.subLinks" :key="i">
              <router-link :to="subLink.route">
                {{ subLink.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "MobileMenu",
  data() {
    return {
      activeLink: null,
      links: [
        {
          title: "Правительство",
          icon: "government",
          route: { name: "persons" },
          subLinks: [
            {
              title: "тест",
              route: { name: "" },
            },
          ],
        },
        {
          title: "Председатель",
          icon: "businessman",
          route: { name: "" },
          subLinks: [
            {
              title: "тест",
              route: { name: "" },
            },
          ],
        },
        {
          title: "Документы",
          icon: "briefcase",
          route: { name: "documents" },
          subLinks: [
            {
              title: "тест",
              route: { name: "" },
            },
          ],
        },
        {
          title: "Пресс-центр",
          icon: "newspaper",
          route: { name: "press" },
          subLinks: [
            {
              title: "Обсуждаемое",
              route: { name: "press" },
            },
            {
              title: "Новости",
              route: { name: "news" },
            },
            {
              title: "Анонсы",
              route: { name: "" },
            },
            {
              title: "Медиа",
              route: { name: "media" },
            },
          ],
        },
        {
          title: "Проектный офис",
          icon: "project",
          route: { name: "projects" },
          subLinks: [
            {
              title: "тест",
              route: { name: "" },
            },
          ],
        },
        {
          title: "Контакты",
          icon: "contacts",
          route: { name: "contacts" },
        },
      ],
    };
  },
  watch: {
    "$route.name"() {
      document.body.classList.remove("no-scroll");
      this.$store.state.showMobileMenu = false;
    },
  },
  methods: {
    closeMobileMenu() {
      document.body.classList.remove("no-scroll");
      this.$store.state.showMobileMenu = false;
    },
    toggleLink(i) {
      if (this.activeLink === i) {
        this.activeLink = null;
        return;
      }
      this.activeLink = i;
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/m-menu.styl"
</style>
