<template>
  <div class="header-mobile">
    <div class="header-mobile__top">
      <router-link :to="{ name: 'home' }">
        <img src="/static/svg/logo.svg" alt="логотип" class="header-mobile__logo" />
      </router-link>
      <div @click="openMobileMenu" class="header-mobile__burger">
        <span class="header-mobile__burger-title">Меню</span>
        <IconComponent name="burger" class="header-mobile__burger-icon" />
      </div>
    </div>
    <div class="header-mobile__bottom">
      <a v-if="phone" :href="`tel:${phone}`" class="header-mobile__bottom-item">
        <IconComponent name="phone-fill" />
        <span>{{ phone }}</span>
      </a>
      <router-link to="" class="header-mobile__bottom-item">
        <IconComponent name="question-answer-fill" />
        <span>Обращения граждан</span>
      </router-link>
      <div class="header-mobile__bottom-item bvi-open">
        <IconComponent name="eye-fill" />
        <span>Для слабовидящих</span>
      </div>
      <router-link to="" class="header-mobile__bottom-item">
        <IconComponent name="search-2-fill" />
        <span>Поиск</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HeaderMobileComponent",
  data() {
    return {
      phone: "8 (8722) 51-76-59",
    };
  },
  methods: {
    openMobileMenu() {
      document.body.classList.add("no-scroll");
      this.$store.state.showMobileMenu = true;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.header-mobile {
  display none
  flex-direction column
  background var(--gray)
  +below(850px) {
    display flex
  }

  &__top {
    padding 15px 30px
    display flex
    justify-content space-between
  }

  &__logo {
    max-width 223px
    max-height 55px
  }

  &__burger {
    display flex
    align-items center
    gap 10px
  }

  &__burger-title {
    font-size .875rem
    line-height 22px
    +below(372px) {
      display none
    }
  }

  &__burger-icon {
    max-width 30px
    max-height 13px
  }

  &__bottom {
    display flex
    justify-content center
    flex-wrap wrap
    border-top 1px solid var(--gray-dark)
    +below(428px) {
      display grid
      grid-template-columns repeat(2, 1fr)
      flex-wrap nowrap
    }
  }

  &__bottom-item {
    width 100%
    max-width 214px
    display flex
    //justify-content center
    align-items center
    gap 5px
    padding 10px
    cursor pointer
    +below(428px) {
      border-bottom 1px solid var(--gray-dark)
      border-right 1px solid var(--gray-dark)
    }
    +below(372px) {
      padding 10px 5px
    }

    .icon svg {
      max-width 16px
      max-height 16px
    }

    span {
      font-size .875rem
      line-height 22px
    }
  }
}
</style>
