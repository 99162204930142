<template>
  <footer class="footer">
    <div class="container container-padding">
      <div class="footer__inner">
        <div class="footer__block footer__block--first">
          <IconComponent name="logo_footer" />
          <span>
            © 2023. Правительство Республики Дагестан. При перепечатке материалов ссылка обязательна.
          </span>
        </div>
        <div class="footer__block">
          <p class="footer__block-title">Правительство</p>
          <div class="footer__block-line" />
          <ul>
            <li v-for="(item, i) in government" :key="i">
              <a :href="item.link">
                {{ item.title }}
                <IconComponent name="right-arrow" />
              </a>
            </li>
          </ul>
        </div>
        <div class="footer__block">
          <p class="footer__block-title">Пользователям</p>
          <div class="footer__block-line" />
          <ul>
            <li v-for="(item, i) in forUsers" :key="i">
              <a :href="item.link">
                {{ item.title }}
                <IconComponent name="right-arrow" />
              </a>
            </li>
            <li>
              <div class="footer__block-bvi">
                <IconComponent name="eye" />
              </div>
              Версия для слабовидящих
            </li>
          </ul>
        </div>
        <div class="footer__block footer__block--last">
          <p class="footer__block-title">Контакты</p>
          <div class="footer__block-line" />
          <ul>
            <li v-if="department.address" style="max-width: 313px">
              <span>
                {{ department.address }}
              </span>
            </li>
            <li>
              <a v-show="department.phone" :href="`tel:${department.phone}`" class="footer__block-link">
                Телефон:
                <span>{{ department.phone }}</span>
              </a>
              <a v-show="department.email" :href="`mailto:${department.email}`" class="footer__block-link">
                E-mail:
                <span>{{ department.email }}</span>
              </a>
            </li>
          </ul>
          <div v-show="department.socials && department.socials.length" class="footer__block-btns">
            <a
              v-for="(social, i) in department.socials"
              :key="i"
              :href="social.link"
              target="_blank"
              class="footer__block-btn"
            >
              <IconComponent category="default" :name="social.type.code | socialIconName" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "FooterComponent",
  data() {
    return {
      government: [
        {
          title: "О правительстве",
          link: "",
        },
        {
          title: "Председатель правительства Республики Дагестан",
          link: "",
        },
        {
          title: "Состав правительства",
          link: "",
        },
        {
          title: "Основы деятельности",
          link: "",
        },
        {
          title: "Органы исполнительной власти",
          link: "",
        },
      ],
      forUsers: [
        {
          title: "Обращения граждан",
          link: "/appeals/form",
        },
        {
          title: "Контакты",
          link: "",
        },
        {
          title: "Поиск по сайту",
          link: "/search",
        },
        {
          title: "Политика конфиденциальности",
          link: "",
        },
      ],
    };
  },
  computed: {
    department() {
      return this.$store.state.department || {};
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.footer{
  display flex
  padding 50px 0 56px 0
  background var(--main)

  &__inner{
    display flex
    gap 50px
    background var(--main)
    +below(1292px) {
      flex-direction column
      gap 30px
    }
  }

  &__block{
    display flex
    flex-direction column
    //margin-left 50px

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--white);
    }

    ul {
      display flex
      flex-direction column
      gap 15px
    }

    a {
      display flex
      justify-content space-between
      gap 30px
      width: 100%;
      font-weight: 490;
      font-size: 14px;
      line-height: 20px;
      color: #9BE3BF;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: #9BE3BF;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 490;
      font-size: 14px;
      line-height: 20px;
      color: #9BE3BF;
    }
  }

  &__block--first {
    gap 30px
    //margin-right  168px
    max-width 312px
    +below(1292px) {
      order 1

      .icon {
        display none
      }
    }

    .icon {
      max-width 284px
      max-height 70px
    }
  }

  &__block--last {

    ul {
      gap 20px
    }

    li {
      display flex
      flex-direction column
      gap 0px

      a {
        display inline
        color var(--white)
      }
    }
  }

  &__block-line {
    border: 1px solid rgba(218, 229, 239, 0.3);
    margin-bottom 20px
  }

  &__block-title {
    margin-bottom 20px
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: var(--white);
  }

  &__block-btns {
    display flex
    gap 5px
    margin-top 20px

    .footer__block-btn {

      svg {
        width: 16px;
        height: 16px;

        path {
          fill var(--white)
        }
      }
    }
  }

  &__block-bvi {
    display flex

    .icon svg {
      width 16px
      height 16px

      path {
        fill var(--main-light)
      }
    }
  }

  &__block-btn {
      display flex
      align-items: center;
      align-content: center;
      background-color  transparent
      height: 40px !important
      width: 40px !important
      justify-content: center
      padding 10px
      border: 1px solid rgba(255, 255, 255, 0.15);

      svg {
        height 11px
        width: 14px

        path {
          fill #9BE3BF;
        }
      }
  }
}
</style>
