export default {
  namespaced: true,
  state: () => ({
    projects_item: null,
    projects_paginate: {
      data: [],
    },
  }),
  actions: {
    save({ state }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
    },
  },
};
