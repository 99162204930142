<template>
  <header>
    <HeaderMobileComponent />
    <HeaderMainComponent />
    <HeaderNavComponent />
  </header>
</template>

<script>
import HeaderMainComponent from "components/header/components/HeaderMainComponent.vue";
import HeaderNavComponent from "components/header/components/HeaderNavComponent.vue";
import HeaderMobileComponent from "components/header/components/HeaderMobileComponent.vue";
export default {
  name: "HeaderComponent",
  components: {
    HeaderMobileComponent,
    HeaderNavComponent,
    HeaderMainComponent,
  },
  mounted() {
    require(["bvi"], ({ Bvi }) => {
      new Bvi();
    });
  },
};
</script>

<style lang="stylus"></style>
