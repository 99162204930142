import Vue from "vue";
import moment from "moment";
import { VueMaskFilter } from "v-mask";
import plural from "plural-ru";
import punycode from "punycode/";

Vue.filter("socialIconName", (code) => {
  switch (code) {
    case 1: {
      return "tg";
    }
    case 2: {
      return "wp";
    }
    case 3: {
      return "vk";
    }
    case 4: {
      return "ok";
    }
    case 5: {
      return "tw";
    }
    case 6: {
      return "yt";
    }
    case 7: {
      return "tt";
    }
    case 8: {
      return "rt";
    }
    case 9: {
      return "dz";
    }
  }
  return "";
});

Vue.filter("url_domain", (v) => {
  try {
    new URL(v);
  } catch {
    return "";
  }
  return punycode.toUnicode(new URL(v).hostname);
});

Vue.filter("formatDate", (date) => {
  return moment(date).format("DD.MM.YYYY");
});

Vue.filter("humanDate", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM YYYY");
});

Vue.filter("mediaDate", (date) => {
  moment.locale("ru");
  return moment(date).format("DD MMMM HH:MM");
});

Vue.filter("robotDate", (date) => {
  return moment(date);
});

Vue.filter("day", (date) => {
  moment.locale("ru");
  return moment(date).format("DD");
});

Vue.filter("monthYear", (date) => {
  moment.locale("ru");
  return moment(date).format("MM.YYYY");
});

Vue.filter("plural", (count, ...words) => {
  return plural(count, ...words.map((w) => "%d " + w));
});

Vue.filter("plural_word", (count, ...words) => {
  return plural(count, ...words.map((w) => w));
});

Vue.filter("vMask", VueMaskFilter);

Vue.filter("image", (entity, api) => {
  if (entity && entity.img) {
    return api + entity.img.url;
  }
  return "/static/images/no-image.png";
});

Vue.filter("image_alt", (entity) => {
  if (entity && entity.img) {
    return entity.img.alt;
  }
  return "";
});

/**
 * Проверка на наличие текста в EditorJs
 * если это текст от EditorJs и там пусто - false
 * undefined/null/'null' - false
 * любой другой текст - true
 */
Vue.filter("editor_has_text", (text) => {
  try {
    let parsedText = JSON.parse(text);
    if (typeof parsedText === "object") {
      return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
    }
    return !!text;
  } catch (e) {
    if (typeof text === "string") {
      return !!text;
    }
    return false;
  }
});

Vue.filter("getArticle", (item, name = "description") => {
  try {
    let json = JSON.parse(item[name]);
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    if (json.blocks) {
      return json;
    }
    return {
      blocks: [
        {
          type: "paragraph",
          data: { text: item[name] },
        },
      ],
    };
  } catch {
    return {
      blocks: [
        {
          type: "paragraph",
          data: { text: item[name] },
        },
      ],
    };
  }
});
