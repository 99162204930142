<template>
  <div class="header-main">
    <div class="container-padding">
      <div class="header-main__inner">
        <router-link :to="{ name: 'home' }">
          <img src="/static/svg/logo.svg" alt="логотип" class="header-main__logo" />
        </router-link>
        <div class="header-main__content">
          <div class="header-main__content-list">
            <div class="header-main__content-item bvi-open">
              <IconComponent name="eye-fill" />
              <span>Версия для слабовидящих</span>
            </div>
            <div
              class="header-main__socials"
              v-if="department && department.socials && department.socials.length"
            >
              <a :href="social.link" target="_blank" v-for="(social, i) in department.socials" :key="i">
                <IconComponent category="default" :name="social.type.code | socialIconName" />
              </a>
            </div>
          </div>
          <div class="header-main__content-list">
            <a
              v-if="department && department.phone"
              :href="`tel:${department.phone}`"
              class="header-main__content-item"
            >
              <IconComponent name="phone-fill" />
              <span>{{ department.phone }}</span>
            </a>
            <router-link :to="{ name: 'appeals-form' }" class="header-main__content-item">
              <IconComponent name="question-answer-fill" />
              <span>Обращения граждан</span>
            </router-link>
            <router-link :to="{ name: 'search' }" class="header-main__content-item">
              <IconComponent name="search-2-fill" />
              <span>Поиск</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "HeaderMainComponent",
  computed: {
    department() {
      return this.$store.state.department;
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.header-main {
  padding 20px 0
  +below(850px) {
    display none
  }

  &__inner {
    display flex
    justify-content space-between
  }

  &__logo {
    max-width 320px
    max-height 78px
  }

  &__content {
    display flex
    flex-direction column
    align-items flex-end
    gap 15px
  }

  &__content-list {
    display flex
    align-items center
    gap 30px
  }

  &__content-item {
    display flex
    align-items center
    gap 5px
    cursor pointer

    span {
      font-size .875rem
      line-height 22px
    }

    .icon {
      max-width 16px
      max-height: 16px

      svg path {
        fill var(--dark)
      }
    }
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px

    a {
      background var(--white)
      border 1px solid var(--gray)
      width 40px
      height 40px
      display flex
      align-items center
      justify-content center

      .icon {
        max-width 20x
        max-height: 20px

        svg path {
          fill var(--main)
        }
      }
    }
  }
}
</style>
