export default {
  namespaced: true,
  state: () => ({
    discussed_news: {},
    minister_news: {},
    department_news: {},
    exception_news: {},
    announcements_news: {},
    media_news: {},
    government_news_types: [],
  }),
  actions: {
    save({ state }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
    },
  },
};
