export default {
  namespaced: true,
  state: () => ({
    minister: null,
    vice_ministers: [],
    first_vice_ministers: [],
    workers_item: null,
  }),
  actions: {
    save({ state }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
    },
  },
};
